<script setup lang="ts">
    import Dialog from 'primevue/dialog';
    import LoginSignup from '@/components/login/LoginSignup.vue';
    import { componentStyle } from '@/styling/util/component-style';

    const emits = defineEmits<{
        (event: 'loginSuccess'): void;
        (event: 'forgotPassword'): void;
        (event: 'signup'): void;
        (event: 'cancelLogin'): void;
    }>();

    function loginSuccess(): void {
        emits('loginSuccess');
    }

    function forgotPassword(): void {
        emits('forgotPassword');
    }

    function signup(): void {
        emits('signup');
    }

    const dialogStyle = componentStyle({
        contentPadding: '0',
    });
</script>

<template>
    <Dialog
        :visible="true"
        modal
        :block-scroll="true"
        header=" "
        class="login-dialog"
        :dt="dialogStyle"
        @update:visible="emits('cancelLogin')"
    >
        <LoginSignup
            :basket-login="true"
            @login-success="loginSuccess"
            @forgot-password="forgotPassword"
            @signup="signup"
        />
    </Dialog>
</template>
